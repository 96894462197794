/**
 * schooljobsdirect theme custom JS main file
 */
jQuery(document).ready(function($) {
  const ajaxurl = '/wp-admin/admin-ajax.php';

  /**
   * Login Page
   */
  $("#password-lost-form-wrap").hide();
  $(".forgot").click(function () {
    $("#password-lost-form-wrap").show();
    $("#loginform").hide();
    $(".forgot").hide();
    $(".init").hide();
    $(".signin").show();
    $(".return").show();
  });
  if ($(".return").length) {
    $(".return").hide();
  }
  $(".return").click(function () {
    $("#password-lost-form-wrap").hide();
    $(".signin").hide();
    $("#loginform").show();
    $(".forgot").show();
    $(".init").show();
    $(".return").hide();
  });
  // ends //

    $('input[name="selectedLevels[]"]').change(function() {
        $('.hideTill').show();
    });

  $('input[name="selectedLevels[]"]').change(function() {
      // Initialize an empty array to hold the values of all selected checkboxes
        var selectedLevels = [];
        
        // Iterate over each checked checkbox and add its value to the array
        $('input[name="selectedLevels[]"]:checked').each(function() {
            selectedLevels.push($(this).val());
        });

        // Check if any level is selected
        if (selectedLevels.length > 0) {
            // Change label text based on selected levels
            var labelUpdate = selectedLevels.some(level => level === 'Colleges' || level === 'Universities') ?
                            'Departments you want to register for' :
                            'Roles you want to register For';
            $('label[for="roles"]').text(labelUpdate);

            $.ajax({
                type: 'POST',
                url: ajaxurl,
                data: {
                    action: 'get_roles',
                    selectedLevels: selectedLevels // Pass the array of selected levels
                },
                success: function(response) {
                    $('#roles').html(response).prop('disabled', false);

                    // Destroy and reinitialize Select2
                    $('#roles').select2('destroy').select2();
                    $('#roles').prop('multiple', true);

                    $('#roles').select2({
                        placeholder: 'Select Role',
                        allowClear: true // This allows clearing the selection
                    });

                    // Automatically clear the Select2 selection
                    $('#roles').val(null).trigger('change');
                    $('.select2-selection__clear').trigger('click');
                }
            });
        } else {
            $('#roles').html('<option value="" selected disabled>Select Level First</option>').prop('disabled', true);
        }
  });

  // Initialize Select2
  if($('#roles').length){
    $('#roles').select2();
  }
  if($('#counties').length){
    $('#counties').select2({
        maximumSelectionLength: 3,
        placeholder: 'Select up to three counties'
    });
    //counties select
    // Add an event listener to enforce the maximum selection length
    $('#counties').on('select2:select', function (e) {
        if ($('#counties').val().length > 3) {
            // Remove the last selected option if the limit is exceeded
            $('#counties option:selected').last().prop('selected', false);
            $('#counties').trigger('change');
        }
    });
  }

  
  
});

function submitTeachersRegForm() {
  const form = document.getElementById('teachers_registration');
  const passwordInput = document.getElementById('password');
  const formData = new FormData(form);
  const passwordStrengthIndicator = document.getElementById('password-strength');

  // Check password strength before submitting the form
  if (!isPasswordStrong(passwordInput.value)) {
      alert('Please enter a stronger password.');
      return;
  }

  // Reset the password strength indicator if the password is strong
  passwordStrengthIndicator.textContent = '';
  
  fetch('/wp-json/sjd/v1/register/jobseeker', {
      method: 'POST',
      body: formData
  })
  .then(response => {
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
  })
  .then(data => {
      // Handle the response data as needed
      console.log(data);

      // Extract the path from the URL (excluding the domain)
      const path = window.location.pathname;
      // Redirect to success page with referer
      const referer = encodeURIComponent(path);
      window.location.href = `/registration-success?referer=${referer}`;
  })
  .catch(error => {
      console.error('Error submitting form:', error);
  });
}

// Attach the submitForm function to the form submission event
const TeachersRegform = document.getElementById('teachers_registration');
if(TeachersRegform){
    TeachersRegform.addEventListener('submit', function(event) {
        event.preventDefault(); // Prevent the default form submission
        submitTeachersRegForm(); // Call your custom form submission function
    });
}

const SchoolRegform = document.getElementById('school_registration');
if(SchoolRegform){
    console.log('School Registration');
    SchoolRegform.addEventListener('submit', function(event) {
        event.preventDefault(); // Prevent the default form submission
        submitSchoolRegForm(); // Call your custom form submission function
    });
}

const SupplierRegform = document.getElementById('supplier_registration');
if(SupplierRegform){
    console.log('Supplier Registration');
    SupplierRegform.addEventListener('submit', function(event) {
        event.preventDefault(); // Prevent the default form submission
        submitSupplierRegForm(); // Call your custom form submission function
    });
}

function isPasswordStrong(password) {
  // Minimum length check
  if (password.length < 8) {
      return false;
  }

  // Check for the presence of both letters and numbers
  if (!/[a-zA-Z]/.test(password) || !/\d/.test(password)) {
      return false;
  }

  // If all checks pass, consider the password strong
  return true;
}

function updatePasswordStrength() {
  const password = passwordInput.value;
  const strengthIndicator = document.getElementById('password-strength');

  // Reset the indicator
  strengthIndicator.textContent = '';

  // Minimum length check
  if (password.length < 8) {
      strengthIndicator.textContent = 'Password should be at least 8 characters long.';
      return;
  }

  // Check for the presence of both letters and numbers
  if (!/[a-zA-Z]/.test(password) || !/\d/.test(password)) {
      strengthIndicator.textContent = 'Password should contain both letters and numbers.';
      return;
  }

  // If all checks pass, consider the password strong
  strengthIndicator.textContent = 'Your password is strong! You can now create your account.';
}
// Attach the updatePasswordStrength function to the password input event
const passwordInput = document.getElementById('password');
if(passwordInput){
    passwordInput.addEventListener('input', updatePasswordStrength);
}

function submitSchoolRegForm() {
    const form = document.getElementById('school_registration');
    const passwordInput = document.getElementById('password');
    const formData = new FormData(form);
    const passwordStrengthIndicator = document.getElementById('password-strength');
  
    // Check password strength before submitting the form
    if (!isPasswordStrong(passwordInput.value)) {
        alert('Please enter a stronger password.');
        return;
    }
  
    // Reset the password strength indicator if the password is strong
    passwordStrengthIndicator.textContent = '';
    
    fetch('/wp-json/sjd/v1/register/school', {
        method: 'POST',
        body: formData
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
    })
    .then(data => {
        // Handle the response data as needed
        console.log(data);
  
        // Extract the path from the URL (excluding the domain)
        const path = window.location.pathname;
        // Redirect to success page with referer
        const referer = encodeURIComponent(path);
        window.location.href = `/registration-success?referer=${referer}`;
    })
    .catch(error => {
        console.error('Error submitting form:', error);
    });
}

function submitSupplierRegForm() {
    const form = $('#supplier_registration')[0]; // Get the native form element
    console.log(form);
    const passwordInput = document.getElementById('password');
    const formData = new FormData(form);
    const passwordStrengthIndicator = document.getElementById('password-strength');
  
    // Check password strength before submitting the form
    if (!isPasswordStrong(passwordInput.value)) {
        alert('Please enter a stronger password.');
        return;
    }
  
    // Reset the password strength indicator if the password is strong
    passwordStrengthIndicator.textContent = '';
    
    fetch('/wp-json/sjd/v1/register/suppliers', {
        method: 'POST',
        body: formData
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
    })
    .then(data => {
        // Handle the response data as needed
        console.log(data);
  
        // Extract the path from the URL (excluding the domain)
        const path = window.location.pathname;
        // Redirect to success page with referer
        const referer = encodeURIComponent(path);
        window.location.href = `/registration-success?referer=${referer}`;
    })
    .catch(error => {
        console.error('Error submitting form:', error);
    });
}

/**
 * Package Selection
 */
$(document).ready(function() {
    $('.level-card .btn').on('click', function() {
        // Get the ID of the clicked button
        var btnId = $(this).attr('id');
        
        // Hide all pricing wrappers and show the one that matches the button ID
        $('.pricing-wrapper').hide();
        $('.pricing-wrapper.' + btnId).show();
        
        // Add 'inactive' class to all level cards and remove it from the clicked one
        $('.level-card').addClass('inactive');
        $('.level-card').removeClass('active');
        $(this).closest('.level-card').removeClass('inactive');
        $(this).closest('.level-card').addClass('active');

        if (window.innerWidth < 768) {
            // Smooth scroll to the .pricing-container element
            $('html, body').animate({
                scrollTop: $('.pricing-container').offset().top
            }, 500); // Adjust the duration as you like
        }
    });
});

// $(document).ready(function() {
//     // Function to move the video element
//     function moveVideoForMobile() {
//       // Check if the screen width is less than or equal to 768px, a common breakpoint for mobile devices
//       if ($(window).width() <= 768) {
//         // Select the video element
//         var $video = $('.video_section');
  
//         // Select the target location after which the video should be moved, in this case, right after the h1 title
//         var $targetLocation = $('.hide_image_desktop img');
  
//         // Move the video to the new location
//         $video.insertAfter($targetLocation);
//       }
//     }
  
//     // Call the function initially in case the page is loaded on a mobile device
//     moveVideoForMobile();
  
//     // Optional: Add an event listener for window resize to dynamically adjust based on screen size
//     // This is useful for handling cases where the browser window is resized or the device orientation changes
//     $(window).resize(moveVideoForMobile);
// });
  
